// ==============================
// Custom style
// ==============================
// You can override the variables in _variables.scss to customize the style

$code-background: #282a36;
// $code-background: #e6e6e6;

/* LineTableTD */  .lntd { vertical-align: top; padding: 0; margin: 0; border: 0; }
/* LineTable */  .lntable { border-spacing: 0; padding: 0; margin: 0; border: 0; width: auto; overflow: auto; display: block; }
/* LineHighlight */  .hl { display: block; width: 100%;background-color: #ffffcc }
/* LineNumbersTable */  .lnt { margin-right: 0.4em; padding: 0 0.4em 0 0.4em;color: #7f7f7f }
/* LineNumbers */  .ln { margin-right: 0.4em; padding: 0 0.4em 0 0.4em;color: #7f7f7f }
/* Keyword */  .k { color: #ff79c6 }
/* KeywordConstant */  .kc { color: #ff79c6 }
/* KeywordDeclaration */  .kd { color: #8be9fd; font-style: italic }
/* KeywordNamespace */  .kn { color: #ff79c6 }
/* KeywordPseudo */  .kp { color: #ff79c6 }
/* KeywordReserved */  .kr { color: #ff79c6 }
/* KeywordType */  .kt { color: #8be9fd }
/* NameAttribute */  .na { color: #50fa7b }
/* NameBuiltin */  .nb { color: #8be9fd; font-style: italic }
/* NameClass */  .nc { color: #50fa7b }
/* NameFunction */  .nf { color: #50fa7b }
/* NameLabel */  .nl { color: #8be9fd; font-style: italic }
/* NameTag */  .nt { color: #ff79c6 }
/* NameVariable */  .nv { color: #8be9fd; font-style: italic }
/* NameVariableClass */  .vc { color: #8be9fd; font-style: italic }
/* NameVariableGlobal */  .vg { color: #8be9fd; font-style: italic }
/* NameVariableInstance */  .vi { color: #8be9fd; font-style: italic }
/* LiteralString */  .s { color: #f1fa8c }
/* LiteralStringAffix */  .sa { color: #f1fa8c }
/* LiteralStringBacktick */  .sb { color: #f1fa8c }
/* LiteralStringChar */  .sc { color: #f1fa8c }
/* LiteralStringDelimiter */  .dl { color: #f1fa8c }
/* LiteralStringDoc */  .sd { color: #f1fa8c }
/* LiteralStringDouble */  .s2 { color: #f1fa8c }
/* LiteralStringEscape */  .se { color: #f1fa8c }
/* LiteralStringHeredoc */  .sh { color: #f1fa8c }
/* LiteralStringInterpol */  .si { color: #f1fa8c }
/* LiteralStringOther */  .sx { color: #f1fa8c }
/* LiteralStringRegex */  .sr { color: #f1fa8c }
/* LiteralStringSingle */  .s1 { color: #f1fa8c }
/* LiteralStringSymbol */  .ss { color: #f1fa8c }
/* LiteralNumber */  .m { color: #bd93f9 }
/* LiteralNumberBin */  .mb { color: #bd93f9 }
/* LiteralNumberFloat */  .mf { color: #bd93f9 }
/* LiteralNumberHex */  .mh { color: #bd93f9 }
/* LiteralNumberInteger */  .mi { color: #bd93f9 }
/* LiteralNumberIntegerLong */  .il { color: #bd93f9 }
/* LiteralNumberOct */  .mo { color: #bd93f9 }
/* Operator */  .o { color: #ff79c6 }
/* OperatorWord */  .ow { color: #ff79c6 }
/* Comment */  .c { color: #6272a4 }
/* CommentHashbang */  .ch { color: #6272a4 }
/* CommentMultiline */  .cm { color: #6272a4 }
/* CommentSingle */  .c1 { color: #6272a4 }
/* CommentSpecial */  .cs { color: #6272a4 }
/* CommentPreproc */  .cp { color: #ff79c6 }
/* CommentPreprocFile */  .cpf { color: #ff79c6 }
/* GenericDeleted */  .gd { color: #8b080b }
/* GenericEmph */  .ge { text-decoration: underline }
/* GenericHeading */  .gh { font-weight: bold }
/* GenericInserted */  .gi { font-weight: bold }
/* GenericOutput */  .go { color: #44475a }
/* GenericSubheading */  .gu { font-weight: bold }
/* GenericUnderline */  .gl { text-decoration: underline }
