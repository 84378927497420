code, pre {
  padding: 7px;
  font-size: $code-font-size;
  font-family: $code-font-family;
  background: $code-background;
}

code {
  padding: 3px 5px;
  border-radius: 4px;
  color: $code-color;
}

// highlight.js
figure.highlight {
  margin: 1em 0;
  border-radius: 5px;
  overflow-x: auto;
  box-shadow: 1px 1px 2px rgba(0,0,0,0.125);
  position: relative;
  color: #d6deeb;
  table {
    position: relative;

    &::after {
      position: absolute;
      top: 0;
      right: 0;
      left: 0;
      padding: 2px 7px;
      font-size: $code-font-size;
      font-weight: bold;
      color: darken($gray, 10%);
      background: darken($code-background, 3%);
      content: 'Code';
    }
  }

  @each $sign, $text in $code-type-list {
    &.#{$sign} > table::after {
      content: $text;
    }
  }

  .code {
    pre {
      margin: 0;
      padding: 30px 10px 10px;
    }
  }

  .gutter {
    width: 10px;
    color: $gray;

    pre {
      margin: 0;
      padding: 30px 7px 10px;
    }
  }

  .line {
    // Fix code block null line height and
    // Synchronous gutter and code line highly.
    height: round($code-font-size * 1.5) + 0.8;
  }

  table, tr, td {
    margin: 0;
    padding: 0;
    width: 100%;
    border-collapse: collapse;
  }

  .code {
    .hljs,
    .hljs-subst {
      color: #D8DEE9;
    }
    
    .hljs-selector-tag {
      color: #81A1C1;
    }
    
    .hljs-selector-id {
      color: #8FBCBB;
      font-weight: bold;
    }
    
    .hljs-selector-class {
      color: #8FBCBB;
    }
    
    .hljs-selector-attr {
      color: #8FBCBB;
    }
    
    .hljs-selector-pseudo {
      color: #88C0D0;
    }
    
    .hljs-addition {
      background-color: rgba(163, 190, 140, 0.5);
    }
    
    .hljs-deletion {
      background-color: rgba(191, 97, 106, 0.5);
    }
    
    .hljs-built_in,
    .hljs-type {
      color: #8FBCBB;
    }
    
    .hljs-class {
      color: #8FBCBB;
    }
    
    .hljs-function {
      color: #88C0D0;
    }
    
    .hljs-function > .hljs-title {
      color: #88C0D0;
    }
    
    .hljs-keyword,
    .hljs-literal,
    .hljs-symbol {
      color: #81A1C1;
    }
    
    .hljs-number {
      color: #B48EAD;
    }
    
    .hljs-regexp {
      color: #EBCB8B;
    }
    
    .hljs-string {
      color: #A3BE8C;
    }
    
    .hljs-title {
      color: #8FBCBB;
    }
    
    .hljs-params {
      color: #D8DEE9;
    }
    
    .hljs-bullet {
      color: #81A1C1;
    }
    
    .hljs-code {
      color: #8FBCBB;
    }
    
    .hljs-emphasis {
      font-style: italic;
    }
    
    .hljs-formula {
      color: #8FBCBB;
    }
    
    .hljs-strong {
      font-weight: bold;
    }
    
    .hljs-link:hover {
      text-decoration: underline;
    }
    
    .hljs-quote {
      color: #4C566A;
    }
    
    .hljs-comment {
      color: #4C566A;
    }
    
    .hljs-doctag {
      color: #8FBCBB;
    }
    
    .hljs-meta,
    .hljs-meta-keyword {
      color: #5E81AC;
    }
    
    .hljs-meta-string {
      color: #A3BE8C;
    }
    
    .hljs-attr {
      color: #8FBCBB;
    }
    
    .hljs-attribute {
      color: #D8DEE9;
    }
    
    .hljs-builtin-name {
      color: #81A1C1;
    }
    
    .hljs-name {
      color: #81A1C1;
    }
    
    .hljs-section {
      color: #88C0D0;
    }
    
    .hljs-tag {
      color: #81A1C1;
    }
    
    .hljs-variable {
      color: #D8DEE9;
    }
    
    .hljs-template-variable {
      color: #D8DEE9;
    }
    
    .hljs-template-tag {
      color: #5E81AC;
    }
    
    .abnf .hljs-attribute {
      color: #88C0D0;
    }
    
    .abnf .hljs-symbol {
      color: #EBCB8B;
    }
    
    .apache .hljs-attribute {
      color: #88C0D0;
    }
    
    .apache .hljs-section {
      color: #81A1C1;
    }
    
    .arduino .hljs-built_in {
      color: #88C0D0;
    }
    
    .aspectj .hljs-meta {
      color: #D08770;
    }
    
    .aspectj > .hljs-title {
      color: #88C0D0;
    }
    
    .bnf .hljs-attribute {
      color: #8FBCBB;
    }
    
    .clojure .hljs-name {
      color: #88C0D0;
    }
    
    .clojure .hljs-symbol {
      color: #EBCB8B;
    }
    
    .coq .hljs-built_in {
      color: #88C0D0;
    }
    
    .cpp .hljs-meta-string {
      color: #8FBCBB;
    }
    
    .css .hljs-built_in {
      color: #88C0D0;
    }
    
    .css .hljs-keyword {
      color: #D08770;
    }
    
    .diff .hljs-meta {
      color: #8FBCBB;
    }
    
    .ebnf .hljs-attribute {
      color: #8FBCBB;
    }
    
    .glsl .hljs-built_in {
      color: #88C0D0;
    }
    
    .groovy .hljs-meta:not(:first-child) {
      color: #D08770;
    }
    
    .haxe .hljs-meta {
      color: #D08770;
    }
    
    .java .hljs-meta {
      color: #D08770;
    }
    
    .ldif .hljs-attribute {
      color: #8FBCBB;
    }
    
    .lisp .hljs-name {
      color: #88C0D0;
    }
    
    .lua .hljs-built_in {
      color: #88C0D0;
    }
    
    .moonscript .hljs-built_in {
      color: #88C0D0;
    }
    
    .nginx .hljs-attribute {
      color: #88C0D0;
    }
    
    .nginx .hljs-section {
      color: #5E81AC;
    }
    
    .pf .hljs-built_in {
      color: #88C0D0;
    }
    
    .processing .hljs-built_in {
      color: #88C0D0;
    }
    
    .scss .hljs-keyword {
      color: #81A1C1;
    }
    
    .stylus .hljs-keyword {
      color: #81A1C1;
    }
    
    .swift .hljs-meta {
      color: #D08770;
    }
    
    .vim .hljs-built_in {
      color: #88C0D0;
      font-style: italic;
    }
    
    .yaml .hljs-meta {
      color: #D08770;
    }
  }
}

// chroma
.highlight > .chroma {
  margin: 1em 0;
  border-radius: 5px;
  overflow-x: auto;
  box-shadow: 1px 1px 2px rgba(0,0,0,0.125);
  position: relative;
  background: $code-background;

  code {
    padding: 0;
  }

  table {
    position: relative;

    &::after {
      position: absolute;
      top: 0;
      right: 0;
      left: 0;
      padding: 2px 7px;
      font-size: $code-font-size;
      font-weight: bold;
      color: darken($gray, 10%);
      background: darken($code-background, 3%);
      content: 'Code';
    }
  }

  @each $sign, $text in $code-type-list {
    &.#{$sign} > table::after {
      content: $text;
    }
  }

  .lntd {
    // Fix code block null line height and
    // Synchronous gutter and code line highly.
    line-height: round($code-font-size * 1.5) + 1;

    &:first-child {
      width: 10px;

      pre {
        margin: 0;
        padding: 40px 7px 10px;
      }
    }

    &:last-child {
      vertical-align: top;

      pre {
        margin: 0;
        padding: 40px 10px 10px;
      }
    }
  }

  table, tr, td {
    margin: 0;
    padding: 0;
    width: 100%;
    border-collapse: collapse;
  }

  /* LineNumbersTable */  .lnt { color: $gray; }
  /* LineHighlight */  .hl { display: block; width: 100%; background-color: #ffffcc }
  /* Keyword */  .k { color: #859900 }
  /* KeywordConstant */  .kc { color: #859900; font-weight: bold }
  /* KeywordDeclaration */  .kd { color: #859900 }
  /* KeywordNamespace */  .kn { color: #dc322f; font-weight: bold }
  /* KeywordPseudo */  .kp { color: #859900 }
  /* KeywordReserved */  .kr { color: #859900 }
  /* KeywordType */  .kt { color: #859900; font-weight: bold }
  /* Name */  .n { color: #268bd2 }
  /* NameAttribute */  .na { color: #268bd2 }
  /* NameBuiltin */  .nb { color: #cb4b16 }
  /* NameBuiltinPseudo */  .bp { color: #268bd2 }
  /* NameClass */  .nc { color: #cb4b16 }
  /* NameConstant */  .no { color: #268bd2 }
  /* NameDecorator */  .nd { color: #268bd2 }
  /* NameEntity */  .ni { color: #268bd2 }
  /* NameException */  .ne { color: #268bd2 }
  /* NameFunction */  .nf { color: #268bd2 }
  /* NameFunctionMagic */  .fm { color: #268bd2 }
  /* NameLabel */  .nl { color: #268bd2 }
  /* NameNamespace */  .nn { color: #268bd2 }
  /* NameOther */  .nx { color: #268bd2 }
  /* NameProperty */  .py { color: #268bd2 }
  /* NameTag */  .nt { color: #268bd2; font-weight: bold }
  /* NameVariable */  .nv { color: #268bd2 }
  /* NameVariableClass */  .vc { color: #268bd2 }
  /* NameVariableGlobal */  .vg { color: #268bd2 }
  /* NameVariableInstance */  .vi { color: #268bd2 }
  /* NameVariableMagic */  .vm { color: #268bd2 }
  /* Literal */  .l { color: #2aa198 }
  /* LiteralDate */  .ld { color: #2aa198 }
  /* LiteralString */  .s { color: #2aa198 }
  /* LiteralStringAffix */  .sa { color: #2aa198 }
  /* LiteralStringBacktick */  .sb { color: #2aa198 }
  /* LiteralStringChar */  .sc { color: #2aa198 }
  /* LiteralStringDelimiter */  .dl { color: #2aa198 }
  /* LiteralStringDoc */  .sd { color: #2aa198 }
  /* LiteralStringDouble */  .s2 { color: #2aa198 }
  /* LiteralStringEscape */  .se { color: #2aa198 }
  /* LiteralStringHeredoc */  .sh { color: #2aa198 }
  /* LiteralStringInterpol */  .si { color: #2aa198 }
  /* LiteralStringOther */  .sx { color: #2aa198 }
  /* LiteralStringRegex */  .sr { color: #2aa198 }
  /* LiteralStringSingle */  .s1 { color: #2aa198 }
  /* LiteralStringSymbol */  .ss { color: #2aa198 }
  /* LiteralNumber */  .m { color: #2aa198; font-weight: bold }
  /* LiteralNumberBin */  .mb { color: #2aa198; font-weight: bold }
  /* LiteralNumberFloat */  .mf { color: #2aa198; font-weight: bold }
  /* LiteralNumberHex */  .mh { color: #2aa198; font-weight: bold }
  /* LiteralNumberInteger */  .mi { color: #2aa198; font-weight: bold }
  /* LiteralNumberIntegerLong */  .il { color: #2aa198; font-weight: bold }
  /* LiteralNumberOct */  .mo { color: #2aa198; font-weight: bold }
  /* OperatorWord */  .ow { color: #859900 }
  /* Comment */  .c { color: #93a1a1; font-style: italic }
  /* CommentHashbang */  .ch { color: #93a1a1; font-style: italic }
  /* CommentMultiline */  .cm { color: #93a1a1; font-style: italic }
  /* CommentSingle */  .c1 { color: #93a1a1; font-style: italic }
  /* CommentSpecial */  .cs { color: #93a1a1; font-style: italic }
  /* CommentPreproc */  .cp { color: #93a1a1; font-style: italic }
  /* CommentPreprocFile */  .cpf { color: #93a1a1; font-style: italic }
  /* Generic */  .g { color: #d33682 }
  /* GenericDeleted */  .gd { color: #b58900 }
  /* GenericEmph */  .ge { color: #d33682 }
  /* GenericError */  .gr { color: #d33682 }
  /* GenericHeading */  .gh { color: #d33682 }
  /* GenericInserted */  .gi { color: #859900 }
  /* GenericOutput */  .go { color: #d33682 }
  /* GenericPrompt */  .gp { color: #d33682 }
  /* GenericStrong */  .gs { color: #d33682 }
  /* GenericSubheading */  .gu { color: #d33682 }
  /* GenericTraceback */  .gt { color: #d33682 }
}
